"use client";
import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";
import Image from "next/image";
import { useRouter } from "next/navigation";
import useDetectMobileOS from "@/hooks/useDetectMobileOS";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

export default function PromotionalValantine() {
    const router = useRouter();
    const os = useDetectMobileOS();
    const userType = useSelector((state: RootState) => state.auth.UserType);

    const handleBannerClick = () => {
        const url = os === 'iOS' ? "https://apps.apple.com/in/app/bright-digi-gold-buy-24k-gold/id1640972173" : "https://play.google.com/store/apps/details?id=com.brightdigigold.customer";
        if (url) {
            window.open(url, '_blank');
        }
    };

    return <>
        <div className="bg-themeBlue cursor-pointer" onClick={handleBannerClick} >
            <motion.div
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className=""
            >
                <div className="grid">
                    <motion.div variants={fadeIn("right", "spring", 0.5, 1)}>
                        <Image
                            // className="z-10 w-full"
                            src="/images/valantinebanner.jpg"
                            alt="App Banner"
                            layout="responsive"
                            width={1500}
                            height={600}
                            style={{
                                width: "100%",
                                height: "auto"
                            }} />
                    </motion.div>
                </div>
            </motion.div>
        </div>
    </>;
}
